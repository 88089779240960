var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; 
// see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; 
// see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links

// var feedItemsCarouselBreakpoint = 576; 
// see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel

// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false;
// Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$(document).ready(function () {
    // sticky header search append to menu admin
    $("#pageHeader .headerContent .header-search").appendTo("#menuAdmin");
    // move menu to between logo and main CTA
    function handleMenu() {
        if ($(window).width() > 1025) {
            // Check if the menu's parent is the header content or not
            if (!$("#menuMain").parent().is($("#pageHeader .headerContent"))) {
                // Else append the menu to the header content
                $("#menuMain").appendTo("#pageHeader .headerContent");
            }
        } else {
            // Check if the menu is inserted after or not
            if (!$("#pageHeader .headerContent").next().is($("#menuMain"))) {
                // Else insert the new element after the target element
                $("#menuMain").insertAfter("#pageHeader .headerContent");
            }
        }
    }
    
    // Initial call to handleScreenSize
    handleMenu();
    
    // Attach the handleScreenSize function to the window resize event
    $(window).on('resize', handleMenu);

    // Replace newsletter text on menu bar
    $('#menuAdmin li a[aria-label="Newsletter"').text("Stay in touch");

    // Stories Feed
    if ($(".homeFeed.CheckoutourCommunityStoriesFeed").length) {
        let storiesFeed = $(".homeFeed.CheckoutourCommunityStoriesFeed");
        let button = '<div class="view-all"><a href="/Listing/Category/news-and-stories" class="cta-button">See all stories</a></div>';
        storiesFeed.append(button);
        storiesFeed.insertAfter(".homeIntroWrapper");
    }

    // Carousel Feed
    if ($(".homeFeed.CarouselFeed").length) {
        let prevArrow = '<button type="button" class="prev-slide"></button>';
        let nextArrow = '<button type="button" class="next-slide"></button>';
        let sliderNav = '<div class="slider-nav">' + prevArrow + nextArrow + '</div>';
        $(".homeFeed.CarouselFeed").append(sliderNav);
        $(".homeFeed.CarouselFeed .feedList").slick({
            fade: true,
            dots: false,
            arrows: true,
            prevArrow: $(".homeFeed.CarouselFeed .prev-slide"),
            nextArrow: $(".homeFeed.CarouselFeed .next-slide"),
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            adaptiveHeight: false,
            slidesToShow: 1,
            slidesToScroll: 1
        });
        //add button to each slide article 
        $(".homeFeed.CarouselFeed .feedItem").each(function () {
            const href = $(this).children("a").attr("href");
            const carouselDetail = $(this).find(".feedItemDetailsWrapper");

            $(carouselDetail).append(`<a href="${href}" class="cta-button">Read More</a>`)
        });
        $(".homeFeed.CarouselFeed").insertAfter(".homeBox3");
    }

    // Main carousel homepage
     var carouselBackgroundVideoYouTubeID = "0YqxUcLtB1Y";
    if (carouselBackgroundVideoYouTubeID && $("body").hasClass("homepage") && !$("body").hasClass("subsite")) {
        var iframeSrc = "https://www.youtube.com/embed/" + carouselBackgroundVideoYouTubeID + "?controls=0&rel=0&autoplay=1&mute=1&loop=1&disablekb=1&fs=0&modestbranding=1&playlist=" + carouselBackgroundVideoYouTubeID;
        var iframeElement = '<iframe src="' + iframeSrc + '" frameborder="0" allowfullscreen></iframe>';
        var ytContainer = '<div class="yt-container">' + iframeElement + '</div>';   
        $(".carouselSlide:first-child .carouselSlideTitle").append(ytContainer);
    } 
    if ($(".pageListingPage .listingIntro").length) {
        $(".pageListingPage .listingIntro").prependTo(".pageListingPage .contentBlock");
    } 

    // Google Translate - Brecon Welsh
    if (window.location.pathname === '/pages/category/larche-brecon') {
        $.getScript("https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit", function () {
            // Create a container for the translation widget using jQuery
            const $translateContainer = $('<div id="google_translate_element"></div>');
            // Get the target element as the appending destination
            const $targetElement = $('.listing .contentBlockWrapper .listingIntro');
            // Append the container to the desired location in the DOM using jQuery
            $targetElement.append($translateContainer);
        });
    }

    // Renders unclickable posts as actually unclickable
    $('.listedPost.PostCategory_unclickable').each(function () {
        $(this).find('a').removeAttr("href");
        $(this).find('a').prop("onclick", null).css("cursor", "auto");
        $(this).prop("onclick", null).css("cursor", "auto");
    });

    // Function that actually set offset
    function offsetAnchor(e) {
        // location.hash.length different to 0 to ignore empty anchor (domain.me/page#)
        if (location.hash.length !== 0) {
            // Get the Y position of the element you want to go and place an offset
            window.scrollTo(0, $(e.target.hash).position().top - 255);
        }
    }

    // Catch the event with a time out to perform the offset function properly
    $(document).on('click', 'a[href^="#"]', function (e) {
        window.setTimeout(function () {
            // Send event to get the target id later
            offsetAnchor(e);
        }, 10);
    });

    // For single carousel banners, not on homepage
    if (!($('body').hasClass('homepage'))) {
        // Select the title
        var elementTitle = $('.headerWrapper .headerText .title');
    
        // Get the text content of the h1 element
        var titleText = elementTitle.text();
    
        // Replace words with hyphen with a span-wrapped version
        var modifiedText = titleText.replace(/\b\w*-\w*\b/g, function(match) {
          return '<span class="hyphen">' + match + '</span>';
        });
    
        // Set the modified text back to the h1 element
        elementTitle.html(modifiedText);
    }

    // Check if any page title is missing
    const pageBanner = $('body:not(.homepage) .pageWrapper .headerWrapper .headerText');
    if (!(pageBanner.find('.title').length > 0)) {
        let pageTitle = pageBanner.find(".breadcrumbWrapper .breadcrumbLink a").text().trim();
        $('<h1 class="title">' + pageTitle + '</h1>').appendTo(pageBanner);
    }
});
